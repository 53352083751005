import { Button, Col, Form, Input, message, Modal, Row } from 'antd';
import type { FormInstance } from 'antd/lib/form';
import { phoneReg, request } from 'egenie-common';
import React from 'react';
import { api } from '../../utils';
import { LayoutGuide, LayoutStore } from '../../utils/layout';

const layoutStore = new LayoutStore({
  project: {
    name: '展厅',
    value: 'egenie-ts-gallery',
  },
});
layoutStore.handleInit();

let timer = null;

interface IState {
  isShowPhoneChange: boolean;
  countDown: number;
  isSendCode: boolean;
}

interface RightOperation {
  id: string;
  name: string;
  callback?: () => void;
}

// 导航 示例
export default class Index extends React.Component {
  public state: IState = {
    isShowPhoneChange: false,
    countDown: 60,
    isSendCode: false,
  };

  public phoneFormRef = React.createRef<FormInstance>();

  public toggleChangePhone = (show: boolean): void => {
    this.setState({ isShowPhoneChange: show });
    if (!show) {
      clearInterval(timer);
      this.setState({
        isSendCode: false,
        countDown: 60,
      });
    }
  };

  public handlePhoneOk = (): void => {
    this.phoneFormRef?.current.validateFields().then((values) => {
      request({
        method: 'post',
        url: api.modifyPhone,
        data: values,
      }).then(() => {
        message.success('手机号修改成功！');
        this.toggleChangePhone(false);
      });
    });
  };

  public handlePhoneCode = (): void => {
    this.phoneFormRef?.current.validateFields(['mobile']).then((values) => {
      if (this.state.isSendCode) {
        return null;
      }
      request({
        method: 'post',
        url: api.anonSend,
        data: values,
      }).then(() => {
        this.setState({
          isSendCode: true,
          countDown: 60,
        });
        this.handleCountDown();
      });
    });
  };

  // 倒计时
  public handleCountDown = ((): void => {
    clearInterval(timer);
    timer = setInterval(() => {
      this.setState((state: IState) => ({ countDown: state.countDown - 1 }));
      if (this.state.countDown < 1) {
        clearInterval(timer);
        this.setState({ isSendCode: false });
      }
    }, 1000);
  });

  public changePhoneModal = (): JSX.Element => (
    <Modal
      maskClosable={false}
      onCancel={this.toggleChangePhone.bind(this, false)}
      onOk={this.handlePhoneOk}
      open
      title="修改手机号"
    >
      <Form
        labelCol={{ span: 6 }}
        ref={this.phoneFormRef}
        wrapperCol={{ span: 15 }}
      >
        <Form.Item
          label="请输入新手机号"
          name="mobile"
          rules={[
            {
              required: true,
              pattern: phoneReg,
              message: '请输入正确手机号(11位)',
            },
            {
              validateTrigger: 'onBlur',
              validator(_, value) {
                if (!phoneReg.test(value)) {
                  return Promise.resolve('请输入正确手机号(11位)');
                }
                return Promise.resolve();
              },
            },
          ]}
          validateTrigger={['onChange']}
        >
          <Input autoComplete="off"/>
        </Form.Item>
        <Form.Item
          label="短信验证码"
          name="validCode"
          rules={[
            {
              required: true,
              message: '请输入验证码',
            },
          ]}
          validateTrigger={['onChange']}
        >
          <Row>
            <Col>
              <Input autoComplete="off"/>
            </Col>
            <Col style={{ marginLeft: '8px' }}>
              <Button
                onClick={this.handlePhoneCode}
                type="primary"
              >
                {this.state.isSendCode ? `${this.state.countDown}S后重新获取` : '获取验证码'}
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </Modal>
  );

  public userInfoRight = (): RightOperation[] => {
    return [
      {
        id: 'changePhone',
        name: '修改手机号',
        callback: () => {
          this.toggleChangePhone(true);
        },
      },
    ];
  };

  public getLogoImg = (): JSX.Element => {
    return (
      <img
        onDragStart={(e) => {
          e.preventDefault();
        }}
        src={`${api.oss}/images/galleryLogo.png`}
        style={{ width: '100%' }}
      />
    );
  };

  public defaultDashboard = (): JSX.Element => (
    <div style={{
      position: 'absolute',
      top: '40px',
      left: '60px',
      width: 'calc(100% - 60px)',
      height: 'calc(100% - 40px)',
      background: 'linear-gradient(to bottom, #FAFBFF 0%, #E9EEFF 100%)',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
    }}
    >
      {/* <img*/}
      {/*  src={`${api.oss}/images/galleryHomeBg.png`}*/}
      {/*  style={{*/}
      {/*    width: '100%',*/}
      {/*    height: '100%',*/}
      {/*  }}*/}
      {/* />*/}
      <div style={{
        display: 'flex',
        alignItems: 'flex-start',
        flexDirection: 'column',
      }}
      >
        <div style={{
          fontSize: '92px',
          color: '#00041A',
        }}
        >
          Welcome
        </div>
        <div style={{
          fontSize: '44px',
          color: '#00041A',
        }}
        >
          欢迎进入依链展厅管理系统
        </div>
      </div>
      <img
        src={`${api.oss}/images/galleryHomeBig.png`}
        style={{
          width: '866px',
          height: '866px',
        }}
      />
    </div>
  );

  render(): JSX.Element {
    return (
      <div>
        <LayoutGuide
          defaultDashboard={this.defaultDashboard()}
          haveDashboard={false}
          logoImg={this.getLogoImg()}
          store={layoutStore}
          userInfoRight={this.userInfoRight()}
        />
        {this.state.isShowPhoneChange && this.changePhoneModal()}
      </div>
    );
  }
}
